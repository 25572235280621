<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import { findCurrentSelectedTreeNode } from '@/utils/util'
import { ref, getCurrentInstance, computed, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { getParamsOptions } from '@/utils/common'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'MerchantView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('merchant.merchant-overview'),
    pageType: 'view'
  },
  {
    routesName: 'MerchantEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('merchant.merchant-edit'),
    pageType: 'edit',
    // edit page特有的属性 reset时不将表单置为空
    isResetFields: false
  },
  {
    routesName: 'MerchantNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('merchant.merchant-new'),
    pageType: 'add'
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()
// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})
currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { proxy, appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const tenantList = getParamsOptions('tenantList')
// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const merchantInfo = await store.dispatch('tenant/handleGetMerchant', {
    id
  })
  if (JSON.stringify(merchantInfo) !== '{}') {
    merchantInfo.tenantId =
      findCurrentSelectedTreeNode(tenantList, merchantInfo.tenantId, 'id') || ''
    merchantInfo.createdTime = proxy.$filters.fmtDateTime(
      merchantInfo.createdTime
    )
    pageInfo.value = merchantInfo
  }
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 修复tenantId在初始点击进来页面就爆红问题
const baseFormRef = ref('')
onMounted(() => {
  nextTick(() => {
    baseFormRef.value.handleRemoveFieldValidate('tenantId')
  })
})

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  const form = cloneDeep(data)
  let url = 'handleMerchantAction'
  form.tenantId = form.tenantId
    ? findCurrentSelectedTreeNode(tenantList, form.tenantId, 'name')
    : ''
  if (pageType === 'edit') {
    form.id = id
    url = 'handleUpdateMerchant'
  }
  handleMessageCommit(`tenant/${url}`, form).then(() => {
    router.push('/tenant/merchant')
  })
}
</script>

<style scoped></style>
