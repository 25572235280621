import { getParamsOptionsAsync } from '@/utils/common'
import {
  phoneValidator,
  nameValidator,
  numberRangeValidator
} from '@/utils/validate'

export const baseUpdateFormConfig = {
  labelWidth: '190px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'userName',
      type: 'input',
      label: 'common.app-merchant-name',
      otherOptions: {
        disabled: true,
        maxlength: 80
      }
    },
    {
      field: 'firstName',
      type: 'input',
      label: 'user.first-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'middleName',
      type: 'input',
      label: 'user.middle-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'lastName',
      type: 'input',
      label: 'user.last-name',
      otherOptions: {
        maxlength: 50
      }
    },
    {
      field: 'email',
      type: 'input',
      label: 'user.email',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'mobile',
      type: 'input',
      label: 'user.phone',
      otherOptions: {
        maxlength: 20
      }
    },
    {
      field: 'expiryDays',
      type: 'input',
      label: 'user.password-expiry',
      initialValue: '90',
      otherOptions: {
        maxlength: 3
      }
    },
    {
      field: 'address',
      type: 'input',
      label: 'user.address',
      otherOptions: {
        maxlength: 300
      }
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        isSingle: true
      },
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    }
  ],
  validateRules: {
    email: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      {
        type: 'email',
        message: 'general.enter-email-message'
      }
    ],
    firstName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
    ],
    middleName: [{ validator: nameValidator, trigger: 'change' }],
    lastName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: nameValidator, trigger: 'change' }
    ],
    roleId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    tenantId: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    expiryDays: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: numberRangeValidator(30, 90), trigger: 'change' }
    ],
    mobile: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: phoneValidator, trigger: 'change' }
    ]
  }
}
