import { getParamsOptionsAsync } from '@/utils/common.js'

export const baseViewFormConfig = {
  formItems: [
    {
      field: 'userName',
      type: 'input',
      label: 'common.app-merchant-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'firstName',
      type: 'input',
      label: 'user.first-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'middleName',
      type: 'input',
      label: 'user.middle-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'lastName',
      type: 'input',
      label: 'user.last-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'email',
      type: 'input',
      label: 'user.email',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'mobile',
      type: 'input',
      label: 'user.phone',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'expiryDays',
      type: 'input',
      label: 'user.password-expiry',
      initialValue: '90',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'address',
      type: 'input',
      label: 'user.address',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        disabled: true,
        isSingle: true
      },
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    }
  ]
}
